import { ConfigRecord } from '~/composables/pocketbase.types';
import { Ref } from 'vue';

export type Config = keyof ConfigRecord;

export const useConfigStore = defineStore('config', () => {
  const config: Ref<ConfigRecord & { id?: string }> = ref({
    ko_phase_mixed: false,
    ko_phase_open: false,
    team_registration_active: false,
    user_login_active: false,
    user_login_message: '',
    user_registration_active: false,
    user_registration_message: '',
    teammanager_spirit_overview_active: false,
    teammanager_spirit_overview_message: '',
    spirit_entry_active: false,
    spirit_entry_message: '',
    teammanager_settlement_active: false,
    teammanager_settlement_message: '',
    schedule_initialized: false,
    playersfee: 0,
    helperjobs_entries_active: false,
  });
  let loaded = false;

  async function fetchConfig(forceReload?: boolean) {
    if (loaded && !forceReload) {
      // TODO how to make sure updated configs get to users, can we do a time based force refresh?
      return;
    }
    try {
      const pb = usePocketBase();
      const data = await pb
        .collection('config')
        .getFullList({ requestKey: null });
      if (data.length !== 1) {
        console.error(
          'Error: config is missing or more than one config exists!',
        );
        return;
      }

      config.value = data[0];
      loaded = true;
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async function updateConfig(configKey: Config, value: any) {
    const pb = usePocketBase();
    try {
      const newConfig = { ...config.value, [configKey]: value };
      await pb.collection('config').update(config.value.id!, { ...newConfig });
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  fetchConfig();

  return { config, fetchConfig, updateConfig };
});
