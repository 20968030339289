import PocketBase, { ClientResponseError } from 'pocketbase';
import { TypedPocketBase } from './pocketbase.types';

let pb: TypedPocketBase;

export function errorMessage(error: unknown) {
  const errorObj = error as ClientResponseError;
  console.error(errorObj.message);
  return errorObj.message;
}

export const usePocketBase = () => {
  if (!pb) {
    const config = useRuntimeConfig();
    const pb_url = config.public.apiBase;
    pb = new PocketBase(pb_url) as TypedPocketBase;
  }
  return pb;
};
