import checkRoles from '~/utils/check-roles';
import { Roles } from '~/stores/usersStore';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }

  // Get required roles for navigation target
  let requiredRoles = to.meta.requiredRoles as Roles | Roles[];
  if (!requiredRoles) {
    console.log(to.fullPath + ': NO ROLES REQUIRED');

    return;
  }
  if (!Array.isArray(requiredRoles)) {
    requiredRoles = [requiredRoles];
  }

  // Check if all roles are assigned to user
  if (!(await checkRoles(requiredRoles))) {
    return navigateTo('/user/no-access');
  }
});
