import { RolesRolesOptions } from '~/composables/pocketbase.types';
import { Ref } from 'vue';

export type Roles = `${RolesRolesOptions}`;

export type CurrentUser = {
  name: string;
  id: string;
  player: object | null;
  team: object | null;
  roles: RolesRolesOptions[];
  teammanager_team: object | null;
};

export const useUsersStore = defineStore('users', () => {
  const pb = usePocketBase();
  const currentUser: Ref<null | CurrentUser> = ref(null);

  async function refreshCurrentUser() {
    if (pb.authStore.isValid) {
      let roles: RolesRolesOptions[] = [];
      try {
        const data = await pb.collection('roles').getFullList({ filter: `user="${pb.authStore.model?.id}"`, requestKey: null });
        if (data?.length > 0) {
          roles = data[0]?.roles;
        }
      } catch (error) {
        console.log('Error: ', error);
      }
      const player = ref(null);
      if (pb.authStore.model?.player !== "") {
        // get the whole player object from the player id
        player.value = await pb.collection('players').getOne(pb.authStore.model?.player, { expand: ['team'] });
        pb.authStore.model.player = player;
      }
      currentUser.value = {
        name: pb.authStore.model?.name,
        id: pb.authStore.model?.id,
        player: player?.value,
        roles: roles,
        team: player.value?.expand?.team,
      };
      // console.log('Current User: ', currentUser.value);
    } else {
      currentUser.value = null;
    }
  }


  refreshCurrentUser();
  pb.authStore.onChange(async () => await refreshCurrentUser());

  return { currentUser: currentUser, refreshCurrentUser: refreshCurrentUser };
});
