import { Roles } from '~/stores/usersStore';
import { RolesRolesOptions } from '~/composables/pocketbase.types';

export default async function (roles: Roles[]): Promise<boolean> {
  const usersStore = useUsersStore();
  await usersStore.refreshCurrentUser();
  return roles.every((role) =>
    usersStore.currentUser?.roles?.includes(RolesRolesOptions[role]),
  );
}
