import { Config } from '~/stores/configStore';
import checkConfig from '~/utils/check-config';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) {
    return;
  }

  // Get required roles for navigation target
  let requiredConfig = to.meta.requiredConfig as Config | Config[];
  if (!requiredConfig) {
    return;
  }
  if (!Array.isArray(requiredConfig)) {
    requiredConfig = [requiredConfig];
  }

  // Check if all roles are assigned to user
  if (!(await checkConfig(requiredConfig))) {
    return navigateTo('/');
  }
});
