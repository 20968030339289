import validate from "/web/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45configs_45global from "/web/middleware/page-configs.global.ts";
import page_45roles_45global from "/web/middleware/page-roles.global.ts";
import manifest_45route_45rule from "/web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45configs_45global,
  page_45roles_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-authenticated": () => import("/web/middleware/is-authenticated.ts")
}